import {Typography} from '@material-ui/core';
import React from 'react';
import TimeAgo from 'react-timeago';
import frenchStrings from 'react-timeago/lib/language-strings/ru';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';

const formatter = buildFormatter(frenchStrings);

export const LastUsedCell: React.FC<{lastUsed: string | null}> = ({lastUsed}) => {
    if (lastUsed === null) {
        return <Typography>-</Typography>;
    }

    if (+new Date(lastUsed) + 300000 > Date.now()) {
        return <Typography title={lastUsed}>Недавно</Typography>;
    }

    return <TimeAgo date={lastUsed} formatter={formatter} />;
};
